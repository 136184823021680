<template>
  <b-row no-gutters
    ><b-col cols="4" class="text-left"
      ><span class="text-uppercase medium grey-subheading">{{
        $t("events.olympics.tutorial.general_info")
      }}</span></b-col
    ><b-col cols="8"></b-col><b-col cols="3"></b-col
    ><b-col class="text-left mt-5"
      ><p class="green text-uppercase bold larger text-shadow">
        {{ $t("events.olympics.tutorial.page_8.heading") }}
      </p>
      <p class="text-white medium large">
        {{ $t("events.olympics.tutorial.page_8.par_1") }}
      </p>
      <p class="text-white medium large">
        {{ $t("events.olympics.tutorial.page_8.par_2") }}
      </p>
      <p class="green medium large">
        {{ $t("events.olympics.tutorial.page_8.par_3") }}
      </p></b-col
    ></b-row
  >
</template>
<script>
export default {
  name: "ModalOlympiaTutorialPage8",
};
</script>
<style scoped lang="scss">
p {
  margin-bottom: 32px;
}
</style>
